import { Message } from 'element-ui';
import router from '@/router'

export default {
  /* createObjectURL */
  createObjectURL(raw) {
    try {
      return URL.createObjectURL(raw);
    } catch (err) {
      console.error(err);
    }
  },
  /* 下载文件 */
  download(url, data, method) {
    if (typeof url === 'object') {
      method = data;
      data = url;
      url = process.env.VUE_APP_API_BASE_URL + '/file/download';
    }
    data = data || {};
    method = method || 'post';
    if (method.toLowerCase() === 'post') {
      let s = `<html><body><form id="form" action="${url}" method="${method}">`;
      for (let key in data) s += `<textarea name="${key}">${data[key]}</textarea>`;
      s += '</form></body></html>';

      let iframe = document.getElementById('downloadFrame');
      if (iframe == null) {
        iframe = document.createElement('iframe');
        iframe.id = 'downloadFrame';
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
      }

      let iWindow = iframe.contentWindow;
      let iDocument = iWindow.document;
      iWindow.focus();
      iDocument.open();
      iDocument.write(s);
      iDocument.close();

      let iform = iDocument.getElementById('form');
      iform.submit();
      iform.remove();
    } else {
      var param = '';
      for (var key in data) {
        if (!param) {
          param = '?' + key + '=' + data[key];
        } else {
          param += '&' + key + '=' + data[key];
        }
      }
      window.open(url + param);
    }
  },
  // 图片转base64
  imgToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () { // 图片转base64完成后返回reader对象
        resolve(reader)
      }
      reader.onerror = reject
    })
  },

  /* 是否完整url */
  isUrl(url) {
    return url && url.indexOf('//') == 0 || url.indexOf('http://') == 0 || url.indexOf('https://') == 0;
  },
  /**
   * 获得时间差,时间格式为 年-月-日 小时:分钟:秒 或者 年/月/日 小时：分钟：秒
   * 其中，年月日为全格式，例如 ： 2010-10-12 01:00:00
   * 返回精度为：秒，分，小时，天
   */
  getDateDiff: function (startTime, endTime, diffType) {
    if (typeof startTime === 'string') startTime = new Date(startTime)
    if (typeof endTime === 'string') endTime = new Date(endTime)

    var sTime = startTime;    //开始时间
    var eTime = endTime;  //结束时间

    //作为除数的数字
    var divNum = 1;

    switch (diffType) {
      case "ss":
        divNum = 1000;
        break;
      case "mm":
        divNum = 1000 * 60;
        break;
      case "HH":
        divNum = 1000 * 3600;
        break;
      case "dd":
        divNum = 1000 * 3600 * 24;
        break;
      default:
        break;
    }

    return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(divNum));
  },
  toShop(type, id) {
    let routeData = {};

    routeData = router.resolve({
      path: "/shop",
      query: { id: id }
    });



    window.open(routeData.href, "_blank");
  },

  toDetail(type, id) {
    let routeData = {};

    routeData = router.resolve({
      path: "/detail",
      query: { id: id }
    });

    // if (type == 2) {
    //   routeData =  router.resolve({
    //     path: "/detail-supplier",
    //     query: { id: id }
    //   });
    // }

    // if (type == 3) {
    //   routeData =  router.resolve({
    //     path: "/detail-4s",
    //     query: { id: id }
    //   });
    // }
    window.open(routeData.href, "_blank");
  },
  getRequest(tag, url) {
    var pattern = /(\w+)=([^&]*)(&|$)/ig, params = {}; //定义正则表达式和一个空对象 /(\w+)=([^&]*)/ig  , /(\w*)=([a-zA-Z0-9\u4e00-\u9fa5]+)/ig
    (url || window.location.href).replace(pattern, function (a, b, c) { params[b] = decodeURIComponent(c); });
    if (tag)
      return params[tag] ? params[tag] : null;
    else
      return params;
  },
  goBack() {
    router.go(-1);
  },

  //跳转到咨询
  toTalk(qq) {
    if (!qq) { Message.error('客服暂不在线') }
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      if (
        !u.match(
          /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
        )
      ) {
        window.open(
          `http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`
        );
      } else {
        window.open(
          `mqqwpa://im/chat?chat_type=wpa&uin=${qq}&version=1&src_type=web&web_src=oicqzone.com`
        );
      }
    } else {
      if (
        !u.match(
          /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
        )
      ) {
        window.open(
          `http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`
        );
      } else {
        window.open(
          `mqqwpa://im/chat?chat_type=wpa&uin=${qq}&version=1&src_type=web&web_src=oicqzone.com`
        );
      }
    }
  },



}