/**
 * token操作封装
 */
import { IdStorageName, TokenStorageName } from './config';

/**
 * 获取缓存的id
 * @returns {string}
 */
export function getId() {
    return localStorage.getItem(IdStorageName);
}

/**
 * 获取缓存的token
 * @returns {string}
 */
export function getToken() {
    return localStorage.getItem(TokenStorageName);
}

/**
 * 缓存token
 * @param token token
 */
export function setToken(token, id) {
    id = id || getId();

    removeToken();
    localStorage.setItem(IdStorageName, id);
    localStorage.setItem(TokenStorageName, token);
}

/**
 * 移除缓存的token
 */
export function removeToken() {
    localStorage.removeItem(IdStorageName);
    localStorage.removeItem(TokenStorageName);
}
