<template>
  <div class="foot">
    <div class="content">

      <div class="foot_top">
        <div class="FootTitle">
          <ul>
            <li
              v-for="(item,index) in list"
              :key="index"
            >
              <h2>{{item.ac_name}}</h2>
              <dl>

                <dd
                  v-for="(i,o) in item.article"
                  :key="o"
                >
                  <router-link
                    :to="i.article_url?i.article_url:{path:'/article',query:{id:i.article_id}}"
                    target="_blank"
                    tag="a"
                  > {{i.article_title}}</router-link>
                </dd>
              </dl>
            </li>

          </ul>
        </div>
      </div>

    </div>
    <div class="rightcopy">
      <p>
        <!-- 首页 | 关于我们 | 广告服务 | 站点地图 | 法律顾问 | 征稿 | 推荐站点 | 合作伙伴 | 联系我们 | 展会合作 网站客服 -->
        Copyright © 2006-2021　861718.com　All rights reserved 版权所有 © 广州德禄讯信息科技有限公司 <br />
        本站转载或引用文章涉及版权问题请与我们联系。电话：020-34224268 传真: 020-34113782 <br />

        <br />
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502001918"
        >
          <img
            style="width: 30px;height:25px;margin-left:10px;  vertical-align: middle;"
            src="@/assets/images/beian.png"
          />
          粤公网安备 44010502001918号</a>

        <a
          target="_blank"
          href="http://beian.miit.gov.cn/"
        >
          <img
            style="width: 30px;height:25px;margin-left:10px; vertical-align: middle;"
            src="@/assets/images/beian.png"
          />
          粤ICP备16022018号-1</a>
          <a
          target="_blank"
          href="https://shop.yishangm.com/upload/common/value_added_service_license.jpg"><img
            style="width: 25px;height:25px;margin-left:10px; vertical-align: middle;"
            src="https://shop.yishangm.com/upload/common/value_added_service_license.jpg"
          /> 增值业务经营许可证：粤B2-20230275</a>
        <a
          target="_blank"
          href="https://shop.yishangm.com/uploads//homeImage/20220808/oC6MJpGJv7OYoyIUDd8Ds2ptxZb8UDCaQK7tQd79.jpeg"><img
            style="width: 25px;height:25px;margin-left:10px; vertical-align: middle;"
            src="https://shop.yishangm.com/uploads//homeImage/20220727/WC37FJi1OBn11Cfvpk5OxON4XXwy2EIPEfsXmXCy.jpeg"
          /> 营业执照</a>

        <!-- {{ web_site_icp}} {{copyright}} -->
      </p>
      <ul class="links">
        <li>
          友情链接：&nbsp;&nbsp;
        </li>
        <li>
          <a
            target="_blank"
            href="http://www.861718.com"
          >仪商网</a>
        </li>
        <li>
          <span>|</span> <a
            target="_blank"
            href="http://www.861718.com"
          >仪器信息网</a>
        </li>
        <li>
          <span>|</span> <a
            target="_blank"
            href="http://www.yishangt.com/"
          >仪商宝</a>
        </li>
        <li>
          <span>|</span> <a
            target="_blank"
            href="http://abb.6li.com"
          >ABB断路器</a>
        </li>
        <li>
          <span>|</span> <a
            target="_blank"
            href="http://www.isa1751.com"
          >二手仪器</a>
        </li>
      </ul>
    </div>

  </div>

</template>
<script>
export default {
  data() {
    return {
      FootTitle: "",
      ShoppingGuide: "", //购物指南
      HelpCenter: "", //支付帮助
      Distribution: "", //配送帮助
      service: "", //售后服务
      helpInformation: "", //帮助信息,
      copyright: "",
      web_site_icp: "",
      list: undefined
    };
  },
  methods: {
    getList() {
      this.$get("/home/bottomArticle").then(res => {
        this.list = res;
        console.log("res.data", res.data);
      });
    }
  },
  mounted() {
    this.getList();
    this.$get("admin/system").then(res => {
      this.copyright = res.items.find(
        i => i.name == "web_site_copyright"
      ).value;
      this.web_site_icp = res.items.find(i => i.name == "web_site_icp").value;
    });
    this.$get("/home/articleClassify").then(res => {
      this.FootTitle = res;
      console.log("  this.FootTitle", this.FootTitle);
    });
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-button {
  width: 80px !important;
  padding: 8px !important;
}
.links {
  text-align: center;
  li {
    display: inline-block;
    margin: 10px;
  }
}
.rightcopy {
  padding: 20px;
  line-height: 24px;
  text-align: center;
}
.foot {
  margin-top: 10px;

  padding-top: 20px !important;

  background: #fff;

  width: 100%;

  .content {
    border-bottom: 1px solid #ccc;
    width: 1226px;
    margin: 0 auto;
  }
  .content::after {
    display: block;
    clear: both;
    content: "";
  }
  .foot_top::after {
    display: block;
    clear: both;
    content: "";
  }
  .cut-off_rule::after {
    display: block;
    clear: both;
    content: "";
  }
  .foot_top {
    .FootTitle {
      padding-bottom: 10px;
      ul {
        display: flex;

        justify-content: center;

        align-items: flex-start;
        text-align: center;

        li {
          display: inline-block;
          h2 {
            width: 206px;
            text-align: center;
            font-size: 16px;
            height: 50px;
            line-height: 40px;
          }
          dl {
            width: 100%;
            dd {
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .cut-off_rule {
    text-align: center;
    vertical-align: top;
    ul {
      overflow: hidden;
      display: inline-block;
      height: 100px;
      padding: 0;
      margin: 0;

      width: 206px;
      li {
        text-align: center;
        height: 25px;
        a {
          text-align: center;
          cursor: pointer;
        }
      }
      li a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
