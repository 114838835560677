import { getId } from '../assets/config/token'
import { HistorySearchStorageName } from '../assets/config/config'

export default {
    // 历史搜索记录
    historySearch: JSON.parse(localStorage.getItem(HistorySearchStorageName) || '[]'),
    // 购物车数据
    // cartData: JSON.parse(localStorage.getItem('cartData') || '[]'),
    cartData: [],
    // 会员信息
    member: { id: getId() },
    // 店铺信息
    shop: {}
}
