import { HistorySearchStorageName } from '../assets/config/config';

export default {
    // 缓存历史搜索记录
    setHistorySeach(state, data) {
        if (state.historySearch.some(item => item == data)) return
        if (state.historySearch.length > 5) {
            state.historySearch.splice(state.historySearch.length - 1, 1)
        }
        if (!data) return
        state.historySearch.unshift(data)
        localStorage.setItem(HistorySearchStorageName, JSON.stringify(state.historySearch))
    },
    // 清空历史搜索记录
    clearHistorySeach(state) {
        state.historySearch = []
        localStorage.removeItem(HistorySearchStorageName)
    },
    // 缓存购物车数据
    setCartData(state, data) {
        state.cartData = data
    },
    // 缓存会员信息
    setMember(state, data) {
        state.member = data;
    },
    // 缓存店铺信息
    setShop(state, data) {
        state.shop = data;
    }
}