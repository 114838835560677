import Vue from 'vue'
import router from '@/router'
import { MessageBox } from 'element-ui';
import { getId, getToken } from '../assets/config/token';

export default {
    /* 添加购物车 */
    addCarData(store, data) {
        // 未登录时不执行 
        // if (!getToken()) {
        //     MessageBox.alert('您还未登录', {
        //         confirmButtonText: '去登录',
        //         callback: action => {
        //             if (action != 'confirm') return;
        //             router.push('/login')
        //         }
        //     });
        //     return Promise.reject();
        // }
        return Vue.prototype.$post("home/cart/add", Object.assign(data, {
            member_id: store.state.member.id
        }), { auth: true }).then(() => {
            return store.dispatch("getCarData");
        });
    },

    /* 删除购物车 */
    delCarData(store, data) {
        // 未登录时不执行
        if (!getToken()) Promise.reject();

        let count = 0 //记录下来删除多少个
        data = data.length === undefined ? [data] : data
        return new Promise((resolve) => {
            data.forEach((goods) => {
                Vue.prototype.$post("home/cart/del", {
                    cart_id: goods.cart_id,
                    goods_id: goods.goods_id,
                }, 0).then(() => {
                    count++

                    if (count == data.length) { //再执行
                        store.dispatch("getCarData");
                        resolve()
                    }
                })
            });
        })
    },

    /* 获取购物车数据 */
    getCarData(store) {
        // 未登录时不执行
        // const member_id = getId();
        // if (!member_id) Promise.reject();
        if (!getToken()) Promise.reject();

        return Vue.prototype.$get("home/cart", { member_id: store.state.member.id }).then((res) => {
            store.commit("setCartData", res.items);
            return res.items;
        })
    }
}