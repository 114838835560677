<template>
  <div class="Header">
    <div class="top">
      <ul class="top_left">
        <li @click="$router.push('/')">
          仪商城首页
        </li>
        <li @click="$router.push('/login')" v-if="!member.id"> 请登录 </li>
        <li v-else>Hi,{{ member.name }}</li>
        <li @click="$router.push('/member-index')" v-if='member.id'>
          会员中心
        </li>
        <li @click="$router.push('/login')" v-else>
          会员中心
        </li>
        <li @click="logout()" v-if="member.id">
          退出
        </li>
        <li @click="$router.push('/register')" v-if="!member.id">
          注册会员
        </li>
        <li @click="shopRegister" v-if='(member.type==2&&!member.shopId)||shop.status!=2'>
          申请入驻
        </li>
        <li v-if='member.type==2&&member.shopId&&shop.status == 2'>
          <a href="https://shop.yishangm.com/dist/index.html" target="_blank">商家中心 </a>
        </li>
      </ul>
      <ul class="top_right">
        <li v-if='member.id' @click="$router.push('/member-coupon')">
          <i class="el-icon-s-ticket themefont"></i>
          我的优惠券
        </li>
        <li v-else @click="$router.push('/login')"> <i class="   el-icon-s-ticket themefont"></i>
          我的优惠券
        </li>
        <li v-if='member.id' @click="$router.push('/member-order')"> <i class="el-icon-document-copy themefont"></i>我的订单 </li>
        <li v-else @click="$router.push('/login')"> <i class="   el-icon-document-copy themefont"></i>
          我的订单
        </li>
        <li v-if='member.id' @click="$router.push('/member-collect')"><i class="el-icon-star-on themefont"></i>收藏夹 </li>
        <li v-else @click="$router.push('/login')"><i class="el-icon-star-on themefont"></i>收藏夹 </li>
        <li @click="$router.push('/cart')">
          <el-badge :value="cartCount" class="item"><i class="el-icon-shopping-cart-2 themefont"></i>
            <i>购物车</i>
          </el-badge>
        </li>
      </ul>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span style='font-size:16px'>入驻仪商店铺需企业账号，个人账号暂时无法入驻，请先注册企业账号。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisible2" width="30%">
      <span style='font-size:16px'>请先登录仪商城账号。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="toLogin">去登录</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import identity from "@/assets/utils/identity";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
    shop() {
      return this.$store.state.shop;
    },
    cartCount() {
      return this.$store.getters.cartCount;
    }
  },
  mounted() { },
  methods: {
    toLogin() {
      this.dialogVisible2 = false;
      this.$router.push("/login");
    },
    shopRegister() {
      if (this.member.id) {
        if (this.member.type == 2) {
          const routeData = this.$router.resolve({
            path: "/shopRegister"
          });

          window.open(routeData.href, "_blank");
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.dialogVisible2 = true;
      }
    },

    logout() {
      identity.logout();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

::v-deep .el-badge__content.is-fixed {
  position: absolute;
  top: 6px !important;
  right: 5px;
}
.Header {
  width: 100%;
  background-color: #e3e4e5;
  height: 30px;
  .top {
    box-sizing: border-box;
    margin: 0 auto;
    height: 30px;
    background-color: #e3e4e5;
    border-bottom: 1px solid #ddd;
    width: 1226px;
    @include space-between;
    .top_left {
      cursor: pointer;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
      @include row;
      li {
        color: #757575;
        margin-right: 15px;
      }
    }
    .top_right {
      cursor: pointer;
      color: #757575;
      line-height: 30px;
      font-size: 14px;
      font-weight: bold;
      @include row-reverse;
      li {
        margin-right: 15px;
      }
    }
  }
}
</style>
