/**
 * axios配置
 */
import axios from 'axios';
import router from '@/router'
import qs from 'qs';
import {
  BaseUrl
} from './config';
import identity from '../utils/identity';
import indicator from '../utils/indicator';
import {
  getToken,
  setToken
} from './token';
import {
  Message,
  MessageBox
} from 'element-ui';

// 设置统一的url
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.baseURL = BaseUrl;

/* 请求拦截器 */
axios.interceptors.request.use(config => {
  if (config.loading) indicator.open(typeof config.loading === 'string' ? config.loading : undefined)
  // 添加token到header
  let token = getToken(); 
  if (token && !config.url.startsWith('https://')) {
    config.headers['token'] = token;
  }
  return config;
}, error => {
  errorState(error);
  return Promise.reject(error);
});

/* 响应拦截器 */
axios.interceptors.response.use(res => {
  // 登录过期处理
  
  if (res.data.code === 70204) {
    if (res.config.auth || router.currentRoute.meta.requireAuth) {
      MessageBox.alert('您还未登录或者登录状态已过期, 请重新登录', {
        confirmButtonText: '去登录'
      }).then(() => {
        identity.logout('/login');
      }).catch(() => {}).finally(() => {
        MessageBox.close()
      });
    } else {
      identity.logout(null);
    }
  }

  // 失败
  if (res.data.code !== 20000 && res.data.code !== 0) {
    failState(res.data, res.config);
    return Promise.reject(res.data || res);
  }

  let token = res.headers['token'];
  if (token) setToken(token);

  if (res.config.loading) indicator.close();

  return res;
}, error => {
  errorState(error);
  return Promise.reject(error);
});

/**
 * 请求失败
 */
function failState(res, config) {
  console.log('failState', res);
  if (config.loading) indicator.close();
  res.code !== 70204 && res.message && Message.error(res.message);
}

/**
 * 请求出错
 */
function errorState(error) {
  console.log('errorState', error)
  if (error.config.loading) indicator.close();
  Message.error('请求出错' + (error.message ? ', ' + error.message : ''))
}

/**
 * 网络请求
 */
function request(url, method, data, config) {
  if (typeof data === 'boolean' || typeof data === 'string') {
    config = {
      loading: data
    };
    data = undefined;
  }
  if (typeof config === 'boolean' || typeof data === 'string') {
    config = {
      loading: config
    };
  }
  if (config && config.headers && config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
    data = qs.stringify(data);
  }

  config = Object.assign({
    url,
    method,
    parse: true,
    params: method === 'get' || method === 'delete' ? data : undefined,
    data: method === 'post' || method === 'put' ? data : undefined,
  }, config);

  return axios(config).then(res => res.data.data || res.data);
}

export default {
  install: function (Vue) {
    // all
    Vue.prototype.$all = (iterable) => Promise.all(iterable);

    // all
    Vue.prototype.$full = (iterable) => Promise.all(iterable.map(a => a.catch(() => {})));

    // post  
    Vue.prototype.$post = (url, data, config) => request(url, 'post', data, config);

    // get
    Vue.prototype.$get = (url, data, config) => request(url, 'get', data, config);

    // put
    Vue.prototype.$put = (url, data, config) => request(url, 'put', data, config);

    // delete
    Vue.prototype.$del = (url, data, config) => request(url, 'delete', data, config);
  }
}