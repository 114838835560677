/*---------------------Date拓展----------------------------*/

/* 增加指定年 */
Date.prototype.addYears = function (value) {
    this.setFullYear(this.getFullYear() + value); return this;
}

/* 增加指定月 */
Date.prototype.addMonths = function (value) {
    this.setMonth(this.getMonth() + value); return this;
}

/* 增加指定天 */
Date.prototype.addDates = function (value) {
    this.setDate(this.getDate() + value); return this;
}

/* 增加指定时 */
Date.prototype.addHours = function (value) {
    this.setHours(this.getHours() + value); return this;
}

/* 增加指定分 */
Date.prototype.addMinutes = function (value) {
    this.setMinutes(this.getMinutes() + value); return this;
}

/* 增加指定秒 */
Date.prototype.addSeconds = function (value) {
    this.setSeconds(this.getSeconds() + value); return this;
}

/* 增加指定毫秒 */
Date.prototype.addMilliseconds = function (value) {
    this.setMilliseconds(this.getMilliseconds() + value); return this;
}

/* 时间对象的格式化 */
Date.prototype.format = function (format) {
    if (this == "Invalid Date") return "";
    if (format == null) format = "yyyy/MM/dd HH:mm:ss";
    var year = this.getFullYear();
    var month = this.getMonth();
    var sMonth = this.monthName[month];
    var date = this.getDate();
    var day = this.getDay();
    var hr = this.getHours();
    var min = this.getMinutes();
    var sec = this.getSeconds();
    var daysInYear = Math.ceil((this - new Date(year, 0, 0)) / 86400000);
    var weekInYear = Math.ceil((daysInYear + new Date(year, 0, 1).getDay()) / 7);
    var weekInMonth = Math.ceil((date + new Date(year, month, 1).getDay()) / 7);
    return format.replace("yyyy", year).
        replace("yy", year.toString().substr(2)).
        replace("y", year.toString().substr(2)).
        replace("MM", (month < 9 ? "0" : "") + (month + 1)).
        replace("M", month + 1).
        replace("dd", (date < 10 ? "0" : "") + date).
        replace("d", date).
        replace("HH", (hr < 10 ? "0" : "") + hr).
        replace("H", hr).
        replace("mm", (min < 10 ? "0" : "") + min).
        replace("m", min).
        replace("ss", (sec < 10 ? "0" : "") + sec).
        replace("s", sec).
        replace("KK", (hr % 12 < 10 ? "0" : "") + hr % 12).
        replace("kk", (hr > 0 && hr < 10 ? "0" : "") + (((hr + 23) % 24) + 1)).
        replace("hh", (hr > 0 && hr < 10 || hr > 12 && hr < 22 ? "0" : "") + (((hr + 11) % 12) + 1)).
        replace("SSS", this % 1000).
        replace("a", (hr < 12 ? "AM" : "PM")).
        replace("W", weekInMonth).
        replace("F", Math.ceil(date / 7)).
        replace(/E/g, this.dayName[day]).
        replace("D", daysInYear).
        replace("w", weekInYear).
        replace(/MMMM+/, sMonth).
        replace("MMM", sMonth.substring(0, 3));
}

/* json格式时间对象的格式化*/
Date.prototype.formatJson = function (date, format) {
    /* 
    * eg:format="yyyy-MM-dd HH:mm:ss"; 
    */
    if (format == undefined || format == null || format.length <= 0)
        format = "yyyy-MM-dd HH:mm:ss";

    if (typeof date === "string")
        date = new Date(parseInt(date.replace("/Date(", "").replace(")/", ""), 10));
    else
        date = this;

    return date.format(format);
}

/* Date 扩展成员 */
/* English */
Date.prototype.monthName = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
Date.prototype.dayName = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
/*中文*/
Date.prototype.monthName = new Array('一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月');
Date.prototype.dayName = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六');