<!-- 文件上传 -->
<template>
  <div class="uploader">
    <el-upload ref="uploader" :action="action" :headers="headers" :data="data" :name="field" :auto-upload="auto" :multiple="multiple" :accept="accept" :drag="drag" :with-credentials="withCredentials"
      :show-file-list="false" :limit="totalNumber" :file-list="fileList" :on-change="onChange" :before-upload="beforeUpload" :on-progress="onProgress" :on-success="onSuccess" :on-error="onError"
      :on-exceed="onExceed">
      <template v-if="drag">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </template>
      <template v-else>
        <el-button type="primary" slot="trigger" icon="el-icon-upload">{{auto ? '上传' : '选择'}}文件</el-button>
        <el-button v-if="!auto" plain @click="handleStart">开始上传</el-button>
      </template>
    </el-upload>
    <div class="uploader-queue" v-if="files.length">
      <div class="uploader-thumb" v-for="(file,index) in files" :key="index">
        <div :class="file.image ? 'uploader-thumb-img' : 'uploader-thumb-icon'">
          <img :src="file.src" :title="file.name">
        </div>
        <div class="uploader-progress" v-if="file.status === 'uploading'">
          <el-progress :percentage="file.percentage" :show-text="false"></el-progress>
        </div>
        <div class="uploader-status" :class="'uploader-' + file.status" v-if="file.percentage && (file.status === 'success' || file.status === 'fail')">
          <el-tooltip :disabled="file.status === 'success'" :content="file.message" placement="top-start">
            <i :class="'el-icon-' + (file.status === 'success' ? 'check' : 'close')"></i>
          </el-tooltip>
        </div>
        <div class="uploader-oper">
          <span v-if="file.image" @click="handlePreview(file)"><i class="el-icon-zoom-in" /></span>
          <span v-if="file.status === 'success'" @click="handleDownload(file)"><i class="el-icon-download" /></span>
          <span @click="handleRemove(file)"><i class="el-icon-delete" /></span>
        </div>
      </div>
    </div>
    <el-image ref="priview" style="display:none" :src="previewUrl" :preview-src-list="previewList" />
  </div>
</template>
<script>
export default {
  name: "Uploader",
  data() {
    return {
      icons: [
        { suffix: ["pdf"], icon: "pdf" },
        { suffix: ["txt"], icon: "txt" },
        { suffix: ["psd"], icon: "psd" },
        { suffix: ["ttf"], icon: "ttf" },
        { suffix: ["apk"], icon: "apk" },
        { suffix: ["exe"], icon: "exe" },
        { suffix: ["torrent"], icon: "bt" },
        { suffix: ["mp3", "wav"], icon: "mp3" },
        { suffix: ["ppt", "pptx"], icon: "ppt" },
        { suffix: ["doc", "docx"], icon: "doc" },
        { suffix: ["xls", "xlsx"], icon: "xls" },
        { suffix: ["html", "htm"], icon: "htm" },
        { suffix: ["swf", "docx"], icon: "flash" },
        { suffix: ["zip", "rar", "7z"], icon: "zip" },
        { suffix: ["mp4", "3gp", "rmvb", "avi", "flv"], icon: "mp4" },
        { suffix: ["gif", "png", "jpeg", "jpg", "bmp"], icon: "img" }
      ],
      files: [],
      images: ['jpg', 'bmp', 'jpeg', 'gif', 'png'],
      previewUrl: undefined,
      previewList: []
    };
  },
  props: {
    // baseUrl
    baseUrl: { type: String, default() { return this.$config.baseUrl } },
    // 上传接口
    url: { type: String, default: '/home/files/content' },
    // 文件字段名 相当于name
    field: { type: String, default: 'file' },
    // 附加参数
    data: Object,
    // 附加头
    headers: Object,
    // 自动上传
    auto: { type: Boolean, default: true },
    // 多选
    multiple: { type: Boolean, default: true },
    // 发送 cookie 凭证
    withCredentials: Boolean,
    // 拖拽上传
    drag: Boolean,
    // 接受上传的文件类型
    accept: String,
    // 允许上传的文件后缀
    exts: [String, Array],
    // 允许上传的大小 MB
    size: Number,
    // 允许上传的总大小 MB
    totalSize: Number,
    // 同时上传的文件数量
    number: Number,
    // 最大上传的文件数量
    totalNumber: Number,
    // 文件列表
    fileList: { type: Array, default() { return []; } },
    // v-model值类型
    valueType: { type: String, default: 'idStr' },
    // 下载方式
    downloadMethod: { type: String, default: 'get' }
  },
  watch: {
    fileList: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.files = this.$refs.uploader.uploadFiles;
          this.files.forEach(a => this.handleFile(a));
          this.emitValue();
        })
      }
    }
  },
  computed: {
    action() {
      return this.$util.isUrl(this.url) ? this.url : this.baseUrl + this.url;
    }
  },
  methods: {
    // 添加文件、上传成功和上传失败
    onChange(file, files) {
      this.handleFile(file);
      let success = this.onSelect(file);
      if (!this.multiple && files.length > 1 && success === true) files.splice(0, 1);
    },
    // 上传中
    onProgress(event, file, files) {
    },
    // 上传成功
    onSuccess(res, file, files) {
      file.status = 'uploading';
      if (res.code != 20000) {
        file.status = 'fail';
        file.message = res.message;
        this.$message.error(res.message);
        return;
      }

      setTimeout(() => {
        file.status = 'success';
        file.id = res.data.id.id || res.data.id;
        file.url = res.data.id.url || res.data.url;
        file.url = this.$util.isUrl(file.url) ? file.url : this.baseUrl + file.url;

        this.emitValue();
      }, 1000)
    },
    // 上传出错
    onError(err, file, files) {
      files.push(file);
      file.message = err.message || '上传出错';
      this.$message.error('上传出错');
    },
    // 最大上传文件数超出限制
    onExceed() {
      this.$message.warning('上传的文件总数超出限制');
    },
    // 选择文件时验证
    onSelect(file) {
      if (this.exts) {
        let exts = typeof this.exts === 'string' ? this.exts.split('|') : this.exts;
        if (exts.indexOf(file.ext) < 0) {
          return this.showMessage('不支持的文件格式', file);
        }
      }
      if (this.number && this.files.filter(a => a.status === 'ready').length > this.number) {
        return this.showMessage('同时上传的文件数超出限制', file);
      }
      if (this.totalNumber && this.files.length > this.totalNumber) {
        return this.showMessage('上传的文件总数超出限制', file);
      }
      if (this.size && file.size / 1024 / 1024 > this.size) {
        return this.showMessage('文件大小超出限制', file);
      }
      if (this.totalSize && this.files.map(a => a.size || 0).reduce((sum, curr) => sum + curr, 0) / 1024 / 1024 > this.totalSize) {
        return this.showMessage('总文件大小超出限制', file);
      }
      return true;
    },
    // 上传之前
    beforeUpload(file) {
      return true;
    },
    // 显示验证信息
    showMessage(message, file) {
      if (!message) return;
      setTimeout(() => { this.$message.warning(message); }, 50)
      file && this.files.splice(this.files.indexOf(file), 1);
    },
    // 开始上传
    handleStart() {
      this.files.filter(a => a.status !== 'success').forEach(a => { a.status = 'ready'; a.percentage = 0 });
      this.$refs.uploader.submit();
    },
    // 预览
    handlePreview(file) {
      this.previewUrl = file.url;
      this.previewList = this.files.filter(a => a.image).map(a => a.url);
      this.$refs.priview.clickHandler();
    },
    // 下载
    handleDownload(file) {
      this.$util.download(file.url, {}, this.downloadMethod);
    },
    // 移除
    handleRemove(file) {
      this.$refs.uploader.handleRemove(file, file.raw);
      this.emitValue();
    },
    // 处理文件
    handleFile(file) {
      file.ext = this.getExt(file);
      file.image = this.isImage(file);
      file.src = this.getThumb(file);
      return file;
    },
    // 同步值
    emitValue() {
      this.$emit('input', this.getValue());
    },
    // 获取值
    getValue() {
      let files = this.files.filter(a => a.status === 'success');
      switch (this.valueType) {
        case 'id':
          return files.map(a => a.id);
        case 'idStr':
          return files.map(a => a.id).join(',');
        case 'url':
          return files.map(a => a.url);
        case 'urlStr':
          return files.map(a => a.url).join(',');
        default:
          return this.multiple ? files : files[0];
      }
    },
    // 获取缩略图
    getThumb(file) {
      if (file.image) return file.url || this.$util.createObjectURL(file.raw);
      return this.getIcon(file)
    },
    // 获取缩略图标
    getIcon(file) {
      let ext = this.getExt(file);
      let item = this.icons.find(a => a.suffix.indexOf(ext) > -1);
      if (item == null) item = { icon: 'file' };
      return require(`@/assets/images/files/${item.icon}.png`);
    },
    // 获取扩展名
    getExt(file) {
      let name = file.name || file.url;
      return name.substr(name.lastIndexOf('.') + 1);
    },
    // 是否为图片
    isImage(file) {
      return this.images.indexOf(this.getExt(file)) > -1;
    },
  }
}
</script>
<style>
.uploader button {
  margin-bottom: 5px;
}
.uploader button:first-child {
  margin-right: 15px;
}
.uploader-queue {
  padding-top: 10px;
}
.uploader-thumb {
  float: left;
  position: relative;
  margin: 0 10px 10px 0px;
  width: 110px;
  height: 110px;
  overflow: hidden;
  /* border: 1px solid #ddd;
  border-radius: 4px; */
  /* transition: all 0.5s ease-in-out; */
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.uploader-thumb:hover {
  background-color: #eee;
}
.uploader-thumb img {
  vertical-align: middle;
}
.uploader-thumb .uploader-thumb-img,
.uploader-thumb .uploader-thumb-icon {
  cursor: pointer;
  line-height: 110px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}
.uploader-thumb .uploader-thumb-img img {
  width: 100%;
  height: 100%;
}
.uploader-thumb .uploader-thumb-icon img {
  width: auto;
  height: auto;
}
.uploader-thumb .uploader-progress {
  bottom: 6px;
  position: relative;
}
.uploader-thumb .uploader-oper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}
.uploader-thumb .uploader-oper:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.uploader-thumb .uploader-oper span {
  display: none;
  cursor: pointer;
}
.uploader-thumb .uploader-oper span + span,
.uploader-thumb .uploader-oper span + span + span {
  margin-left: 12px;
}
.uploader-thumb:hover .uploader-oper {
  opacity: 1;
}
.uploader-thumb:hover .uploader-oper span {
  display: inline;
}
.uploader-thumb .uploader-status {
  position: absolute;
  right: -15px;
  top: -6px;
  width: 40px;
  height: 25px;
  z-index: 1;
  cursor: pointer;
  background: #f56c6c;
  text-align: center;
  transform: rotate(45deg);
  box-shadow: 0 0 1pc 1px rgb(0 0 0 / 20%);
}
.uploader-thumb .uploader-status i {
  color: #fff;
  font-size: 12px;
  transform: rotate(-45deg);
  padding-top: 3px;
}
.uploader-thumb .uploader-status.uploader-success {
  background: #13ce66;
}
</style>