// 引用框架
// import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/assets/config/axios'
import config from '@/assets/config/config'
import validate from '@/assets/utils/validate'
import util from '@/assets/utils/util'
import '@/assets/utils/prototype.js'
import VueLazyload from 'vue-lazyload'


import loading from '@/assets/images/loading.png'
//注册图片懒加载  
Vue.use(VueLazyload, {
  // preLoad: 1.3,
  error: loading,//图片错误的替换图片路径(可以使用变量存储)
  loading: loading,//正在加载的图片路径(可以使用变量存储)
  // attempt: 1
})

// 第三方组件
import VueLuckyCanvas from '@lucky-canvas/vue'

// 扩展组件
import Uploader from './components/global/uploader';  // 文件上传

// UI框架
import ElementUI from 'element-ui'
import '@/assets/styles/theme.scss';


Vue.use(axios)
Vue.use(ElementUI)
Vue.use(VueLuckyCanvas)

Vue.prototype.$util = util
Vue.prototype.$config = config
Vue.prototype.$validate = validate

/* 全局注册常用组件 */
Vue.component(Uploader.name, Uploader);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
