export default {
    cartCount(state) {
        let number = 0
        for (let i = 0; i < state.cartData.length; i++) {
            const shop = state.cartData[i];
            shop.carts_list.filter(f => f.isprice != 2).forEach(item => {
                number += item.quantity
            })
        }
        return number
    },
    cartList(state) {
        let data = [];
        state.cartData.forEach(a => {
            let list = a.carts_list.filter(b => b.isprice != 2);
            if (list.length) data.push({
                ...a,
                carts_list: list
            });
        });
        return data;
    }
}