<template>
  <div id="app">
    <NavHeaderTop v-if='show' />

    <router-view />
    <NavFooter v-if='show' />
  </div>
</template>

<script>
import NavHeaderTop from "./components/layout/nav-header-top";
import NavFooter from "./components/layout/nav-footer";
import identity from "@/assets/utils/identity";
import { setToken } from '@/assets/config/token';

export default {
  components: { NavHeaderTop, NavFooter },
  computed: {
    // show() {
    //   if (

    //      this.$route.path == "/flagShip" 
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  },
  mounted() {
    let token = this.$util.getRequest('token');
    if(token) setToken(token);
    
    identity.getUser();

    this.$get("admin/system").then(res => {
      let title_name = res.items.find(i => i.name == "web_site_title").value;
      document.title = title_name;
      let description_text = res.items.find(i => i.name == "web_site_description").value;
      let keyword_text = res.items.find(i => i.name == "web_site_description").value;

      let keyword = document.createElement("meta");
      keyword.setAttribute("name", 'keyword');
      keyword.setAttribute("content", keyword_text);

      let description = document.createElement("meta");
      description.setAttribute("name", 'description');
      description.setAttribute("content", description_text);

      let head = document.getElementsByTagName("head")[0];
      head.appendChild(keyword);
      head.appendChild(description);
    });
  },
  data() {
    return {
      show: true,
      title: ""
    };
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/mixins";

a {
  color: #757575;
}
::v-deep .el-message {
  top: 500px !important;
}
::v-deep .el-page-header {
  margin-bottom: 20px !important;
}
.center {
  text-align: center;
}
.hover:hover {
  color: red;
  cursor: pointer;
}
.bg_w {
  background: #fff;
}
.content_container {
  position: relative;
  padding: 10px;
  width: 1226px;
  margin: 0 auto !important;
  min-height: 700px;
  box-sizing: border-box;
}
body {
  background: #f5f5f5;
  color: #757575;
}
.themeBg {
  background: #fe6925;
}
.themefont {
  color: #fe6925;
}
.bgColorPrimary {
  color: #fff;
}

.paddingOuter {
  padding: 10px;
}

.active {
  border: 1px solid #fe6925 !important;
  color: #fe6925;
}

.border {
  border: 1px solid #dedcdc !important;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html,
body,
div,
span,
header,
footer,
nav,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
textarea,
select,
figure,
figcaption {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  text-decoration: none;
  border: none;
  box-sizing: border-box;
  font-family: "Microsoft Yahei", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  height: auto;
  font-size: 12px;
}
.sort-list ::after {
  clear: both;
  content: "";
  display: block;
}
.enabled {
  // width: 100%;
  height: 800px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: #999;
    text-decoration: underline;
  }
}
</style>