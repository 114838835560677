import Vue from 'vue'
import { Loading } from 'element-ui'

let instance
const indicator = {
  open(text, options = {}) {
    if (typeof text === 'object') {
      options = text
      text = undefined
    }
    instance = Loading.service(Object.assign({ text: text, background: 'rgba(255, 255, 255, 0.5)' }, options))
    if (instance.text != text) instance.text = text
  },
  close() {
    instance && instance.close()
  }
}

Vue.prototype.$indicator = indicator
export default indicator