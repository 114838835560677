import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { setToken, removeToken, getToken } from '../config/token'

export default {
    // 登录
    login(data, path) {
        setToken(data.member_token, data.member_id);

        this.getUser();
        router.replace({ path: path || '/' }).catch(() => { });
    },

    // 退出
    logout(path = '/') {
        removeToken();
        store.commit('setShop', {});
        store.commit('setMember', {});

        if (path == '/') {
            router.replace({ path }).catch(() => { });
        }
        if (path == '/login') {
            const currentPath = router.currentRoute.fullPath;
            router.replace('/login' + (currentPath && currentPath !== '/' ? '?url=' + encodeURIComponent(currentPath) : ''));
        }
    },

    // 获取用户信息
    getUser() {
        const token = getToken();
        if (!token || store.state.member.name) return;

        Vue.prototype.$post('home/user/info').then(res => { //{ member_id }
            const member = {
                id: res.member_id,
                name: res.account,
                type: res.member_type,
                level: res.level,
                score: res.score,
                shopId: res.shopid,
                status: res.status,
                avatar: res.avatar_url,
                companyStatus: res.company_status
            }
            store.commit('setMember', member);
            store.dispatch("getCarData");
            this.getShop(member.shopId)
        })
    },

    // 获取店铺
    getShop(shopId) {
        if (!shopId) return;

        Vue.prototype.$get("seller/shop/" + shopId).then(res => {
            store.commit('setShop', {
                id: shopId,
                name: res.shop_name,
                status: res.status
            });
        });
    }
}