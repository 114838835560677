import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import { getToken } from '@/assets/config/token'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/home',
  component: () => import('../views/home/home.vue')
},
{
  path: '/home',
  name: 'Home',
  component: () => import('../views/home/home.vue')
},
{
  path: '/coupon',
  name: 'coupon',
  component: () => import('../views/home/coupon.vue')
},
{
  path: '/activity',
  name: 'activity',
  component: () => import('../views/home/activity.vue')
},
{
  path: '/activity1',
  name: 'activity1',
  component: () => import('../views/home/activity1.vue')
},


{
  path: '/login',
  name: 'login',
  component: () => import('../views/user/login.vue')
},
{
  path: '/register',
  name: 'register',
  component: () => import('../views/user/register.vue')
},
{
  path: '/shopRegister',
  name: 'shopRegister',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/user/shopRegister.vue')
},

{
  path: '/forgetPassword',
  name: 'forgetPassword',
  component: () => import('../views/user/forgetPassword.vue')
},

{
  path: '/flagShip',
  name: 'flagShip',
  component: () => import('../views/flagShip/flagShip.vue')
},

{
  path: '/brands',
  name: 'brands',
  component: () => import('../views/brands/brands.vue')
},

{
  path: '/material',
  name: 'material',
  component: () => import('../views/material/material.vue')
},

{
  path: '/chain',
  name: 'chain',
  component: () => import('../views/chain/chain.vue')
},
{
  path: '/chainPublish',
  name: 'chainPublish',
  component: () => import('../views/chain/chainPublish.vue')
},
{
  path: '/chainChat',
  name: 'chainChat',
  component: () => import('../views/chain/chainChat.vue')
},

{
  path: '/contactAdvise',
  name: 'contactAdvise',
  component: () => import('../views/foot/contactAdvise.vue')
},
{
  path: '/agreement',
  name: 'agreement',
  component: () => import('../views/foot/agreement.vue')
},

{
  path: '/contactHarmful',
  name: 'contactHarmful',
  component: () => import('../views/foot/contactHarmful.vue')
},

{
  path: '/shop',
  name: 'shop',
  component: () => import('../views/shop/shop.vue')
},


{
  path: '/shopInner',
  name: 'shopInner',
  component: () => import('../views/shop/shopInner.vue')
},


{
  path: '/shop-level',
  name: 'shop-level',
  component: () => import('../views/shop/shop-level.vue')
},
{
  path: '/detail',
  name: 'detail',
  component: () => import('../views/shop/detail.vue')
},
//双十一页面
{
  path: '/doubleEleven',
  name: 'doubleEleven',
  component: () => import('../views/home/doubleEleven.vue')
},

{
  path: '/searchProduct',
  name: 'searchProduct',
  component: () => import('../views/public/searchProduct.vue')
},

{
  path: '/searchShop',
  name: 'searchShop',
  component: () => import('../views/public/searchShop.vue')
},


{
  path: '/priceMessage',
  name: 'priceMessage',
  component: () => import('../views/public/priceMessage.vue')
},

{
  path: '/priceMessage2',
  name: 'priceMessage2',
  component: () => import('../views/public/priceMessage2.vue')
},

{
  path: '/cart',
  name: 'cart',
  component: () => import('../views/public/cart.vue')
},


{
  path: '/payType',
  name: 'payType',
  component: () => import('../views/public/payType.vue')
},
{
  path: '/pay',
  name: 'pay',
  component: () => import('../views/public/pay.vue')
}, {
  path: '/orderForm',
  name: 'orderForm',
  component: () => import('../views/public/orderForm.vue')
}, {
  path: '/information',
  name: 'information',
  component: () => import('../views/public/information.vue')
},
{
  path: '/sortProduct',
  name: 'sortProduct',
  component: () => import('../views/public/sortProduct.vue')
},
{
  path: '/member-order-logistics',
  name: 'member-order-logistics',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-order/member-order-logistics.vue')
},

{
  path: '/shopList',
  name: 'shopList',
  component: () => import('../views/public/shopList.vue')
},
{
  path: '/member-order-detail',
  name: 'member-order-detail',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-order/member-order-detail.vue')
},
{
  path: '/member-order-complaint',
  name: 'member-order-complaint',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-order/member-order-complaint.vue')
},


{
  path: '/member-seek-add',
  name: 'member-seek-add',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-seek/member-seek-add.vue')
},
{
  path: '/member-seek-edit',
  name: 'member-seek-edit',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-seek/member-seek-edit.vue')
},
{
  path: '/member-complaint-edit',
  name: 'member-complaint-edit',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-complaint/member-complaint-edit.vue')
},
{
  path: '/member-consult-edit',
  name: 'member-consult-edit',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-consult/member-consult-edit.vue')
},
{
  path: '/member-order-invoice',
  name: 'member-order-invoice',
  meta: {
    requireAuth: true
  },

  component: () => import('../views/member/member-order/member-order-invoice.vue')
},



{
  path: '/member-order-refund',
  name: 'member-order-refund',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/member/member-order/member-order-refund.vue')
},
{
  path: '/member-order-evaluate',
  name: 'member-order-evaluate',
  meta: {
    requireAuth: true
  },
  component: () => import('../views/member/member-order/member-order-evaluate.vue')
},
{
  path: '/member-integral-change',
  name: 'member-integral-change',
  component: () => import('../views/member/member-integral/member-integral-change.vue')
},
{
  path: '/member-sider',
  name: 'member-sider',
  component: () => import('../views/member/member-sider.vue'),
  children: [{
    path: '/member-address',
    name: 'member-address',
    component: () => import('../views/member/member-address/member-address.vue')
  },
  {
    path: '/member-index',
    name: 'member-index',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/member/member-index/member-index.vue')
  },
  {
    path: '/member-consult',
    name: 'member-consult',
    meta: {
      requireAuth: true
    },

    component: () => import('../views/member/member-consult/member-consult.vue')
  },
  {
    path: '/member-complaint',
    name: 'member-complaint',
    meta: {
      requireAuth: true
    },

    component: () => import('../views/member/member-complaint/member-complaint.vue')
  },
  {
    path: '/member-seek',
    name: 'member-seek',
    meta: {
      requireAuth: true,
      path: '/login'
    },

    component: () => import('../views/member/member-seek/member-seek.vue')
  },
  {
    path: '/member-order',
    name: 'member-order',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/member/member-order/member-order.vue')
  },
  {
    path: '/member-supply',
    name: 'member-supply',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/member/member-supply/member-supply.vue')
  },
  {
    path: '/member-message',
    name: 'member-message',
    component: () => import('../views/member/member-message/member-message.vue')
  },
  {
    path: '/member-setting',
    name: 'member-setting',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/member/member-setting/member-setting.vue')
  },
  {
    path: '/member-coupon',
    name: 'member-coupon',
    component: () => import('../views/member/member-coupon/member-coupon.vue')
  },

  {
    path: '/member-collect',
    name: 'member-collect',
    meta: {
      requireAuth: true
    },
    component: () => import('../views/member/member-collect/member-collect.vue')
  },

  {
    path: '/member-integral',
    name: 'member-integral',
    component: () => import('../views/member/member-integral/member-integral.vue')
  },


  {
    path: '/member-evaluate',
    name: 'member-evaluate',
    component: () => import('../views/member/member-evaluate/member-evaluate.vue')
  },
  {
    path: '/member-invoice',
    name: 'member-invoice',
    component: () => import('../views/member/member-invoice/member-invoice.vue')
  }
  ]
},
{
  path: '/404',
  component: () => import('../views/public/404.vue')
}
  ,
{
  path: '/article',
  component: () => import('../views/public/article.vue')
},

{
  path: '/close',
  component: () => import('../views/public/close.vue')
}
]

const router = new VueRouter({
  routes,
  // mode: 'history'
})

router.beforeEach((to, from, next) => {
  // console.log('from', from)
  // console.log('to', to)

  if (to.meta.requireAuth) { // 判断跳转的路由是否需要登录
    if (!getToken()) { // 判断是否已登录
      next({
        path: to.meta.path || '/',
        // query: { url: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
      return
    }

    if (to.meta.requireShop) {
      if (!store.state.shop.id) { // 已注册店铺
        // next('/my/shop/register')
        return
      }
    }
  }
  if (!to.matched.length) {
    next('/404?url=' + to.fullPath);
    return;
  }

  next()
})

export default router